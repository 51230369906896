// eslint-disable-next-line import/prefer-default-export
import {
  formatCurrency,
  getPriceFromCentimes,
} from '@les-sherpas/sherpas-toolbox';

// eslint-disable-next-line import/prefer-default-export
export const formatPrice = (price: number) =>
  formatCurrency({
    amount: getPriceFromCentimes(price),
    stripDecimalIfInteger: false,
    showSymbol: true,
  });
